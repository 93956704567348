import {refreshToken } from "@/api/authApi";
import uuidv4 from 'uuid/v4'

export default {
    destroyed() {
        this.clearCountdown();
    },
    methods: {
        getTokenWithSession: function() {
            //console.log("getTokenWithSession");
            var nowAuth = JSON.parse(window.sessionStorage.getItem('LoginAuth'));
            if (this.loginAuth.id_token !== nowAuth.id_token) {
                console.log("refreshToken in mixin");
                this.loginAuth.id_token = nowAuth.id_token;
            }

            return this.loginAuth.id_token;
        },
        refreshToken: function () {
            var vm = this;
    
            var apiPara = new URLSearchParams();
            apiPara.append("account", vm.loginAuth.userID);
            apiPara.append("refreshId", vm.loginAuth.refreshId);
            const csrfToken = uuidv4();
            const header = {
                header: {'X-XSRF-TOKEN': csrfToken} 
            }
    
            refreshToken(apiPara, header).then(function(response) {
                if ("201" == response.status) {
                  vm.loginAuth.id_token = response.data.id_token;
                  window.sessionStorage.setItem('LoginAuth', JSON.stringify(vm.loginAuth));
                }
            });
        },
        authValidate: function() {
            var vm = this;
            if (vm.loginAuth === null) {
                vm.$router.push({ path: "/" });
            }

            if (vm.loginAuth.inout === true && vm.$route.name !== "inout") {
                vm.$router.push({ path: "/" });
            }

            if (!vm.$route.meta.authority.some(function(item) {
                return (item.role === vm.loginAuth.role || item.role === "ALL");
            })) {
                vm.$swal("此頁面無權限");
                vm.$router.push({ path: "/index" });
            }
            //console.log("commonMixin = " + JSON.stringify(this.$route.meta.authority));

            //判斷最後一次操作時間
            var timeDiff = Date.now() - this.loginAuth.timeStart;
            if (timeDiff >= this.loginTimeout) {
                alert("操作逾時");
                this.logout();
            } else {                
                this.resetCountdown();
                this.loginAuth.timeStart = Date.now();
                window.sessionStorage.setItem('LoginAuth', JSON.stringify(this.loginAuth));
                this.initCountdown();
            }
        },
        clearCountdown: function() {
            clearInterval(this.countdown);
            this.countdown = null;
        },
        resetCountdown: function() {
            //記得在主頁面加上@click，轉換頁面時要使用@click.stop
            
            this.loginAuth.timeStart = Date.now();
            window.sessionStorage.setItem('LoginAuth', JSON.stringify(this.loginAuth));
        },
        initCountdown: function(){
            this.countdown = setInterval(() => {
                var nowTime = Date.now();
                var timeDiff = nowTime - this.loginAuth.timeStart;
                var deadline = this.loginAuth.timeStart + this.loginTimeout;

                if (timeDiff > this.loginTimeout) {
                    clearInterval(this.countdown);
                    this.countdown = null;
                    alert("操作逾時");
                    this.logout();
                } else {
                    var offsetTime = (deadline - nowTime) / 1000; // ** 以秒為單位
                    var sec = parseInt(offsetTime % 60); // 秒
                    var min = parseInt((offsetTime / 60) % 60); // 分 ex: 90秒
                   
                    //console.log("countdown timeDiff = " + timeDiff + ", deadline = " + deadline + "," +  this.right("0" + min, 2) + ":" +  this.right("0" + sec, 2));
                    this.logoutTime = this.right("0" + min, 2) + ":" +  this.right("0" + sec, 2);
                }
            },1000);
        },
        logout: function() {
            let vm = this;
            this.loginAuth = null;

            window.sessionStorage.removeItem('LoginAuth');
            window.sessionStorage.clear();
            vm.$router.push({ path: "/" });
        },
        blobToDataURI(blob, callback) { //Blob to base64
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                data = data.replace(/application/, "image");
                data = data.replace(/octet-stream/, "png");
                callback(data);
            }
            reader.readAsDataURL(blob);
        },
        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        right: function(str, num)
        {
            return str.substring(str.length-num,str.length)
        },
        formatDate (date) {
            if (!date) return null
        
            const [year, month, day] = date.split('-')
            return `${year}/${month}/${day}`
        },
        checkPlusFunc(name){
            console.log("checkPlusFunc = " + this.loginAuth.plusFunc);
            return this.loginAuth.plusFunc.some(item => {
                return item === name;
            });
        },
    },
    data: () => ({
        countdown: null,
        timeOutProcessId: null,
        loginAuth: JSON.parse(window.sessionStorage.getItem('LoginAuth')),
        logoutTime: "",
        loginTimeout: 1800000,
    }),
}
