import axios from 'axios';

// 取得token驗證 api
//================================================================
// const request = axios.create({
//   baseURL: '/Hyireco/api',
//   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// });

// const request = axios.create({
//   baseURL: 'http://10.10.66.124/Hyireco/api',
//   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// });

const request = axios.create({
    //baseURL: 'http://10.10.66.124/Hyireco/api',
    baseURL: '/HyirecoM/api/',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

export const apLogin = data => request.post('/apLogin', data); // id_token
export const refreshToken = (data,headers) => request.post('/refreshToken', data, headers);